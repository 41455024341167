<template>
  <div style="position: relative">
    <Breadcrumb :Breadcrumb="BreadcrumbCon" />

    <div class="status">
      <el-input placeholder="请输入手机号" v-model="phone"></el-input>

      <el-date-picker
        v-model="time"
        type="daterange"
        range-separator="至"
        start-placeholder="开始时间"
        end-placeholder="结束时间"
        format="yyyy 年 MM 月 dd 日"
        value-format="yyyy-MM-dd"
        :clearable="false"
      >
      </el-date-picker>

      <el-select v-model="state" placeholder="请选择审核类型">
        <el-option label="车主手动审核列表" value="0"></el-option>
        <el-option label="车主自动审核列表" value="1"></el-option>
        <el-option label="车主失效车辆列表" value="2"></el-option>
      </el-select>

      <el-button type="primary" @click="searchList">搜索</el-button>
      <el-button type="info" @click="reset">重置</el-button>
    </div>

    <div class="table_con">
      <el-table :data="tableData" border style="width: 100%" height="100%">
        <el-table-column label="序号" type="index" width="80" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="phone" label="手机号" width="180">
          <template slot-scope="scope">
            {{ scope.row.phoneArea }} {{ scope.row.phone }}
          </template>
        </el-table-column>
        <el-table-column prop="realName" label="真实姓名" width="180">
        </el-table-column>
        <el-table-column prop="driverSubmitDate" label="提交时间" width="210">
        </el-table-column>
        <el-table-column
          prop="driverLicencePictureMain"
          label="驾驶证"
          width="170"
        >
          <template slot-scope="scope">
            <el-image
              style="width: 150px; height: 150px"
              :src="scope.row.driverLicencePictureMain"
              :preview-src-list="[scope.row.driverLicencePictureMain]"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column
          prop="drivingLicensePictureMain"
          label="行驶证"
          width="170"
        >
          <template slot-scope="scope">
            <el-image
              style="width: 150px; height: 150px"
              :src="scope.row.drivingLicensePictureMain"
              :preview-src-list="[scope.row.drivingLicensePictureMain]"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column
          prop="carFrontPicture"
          label="车辆前面照片"
          width="170"
        >
          <template slot-scope="scope">
            <el-image
              style="width: 150px; height: 150px"
              :src="scope.row.carFrontPicture"
              :preview-src-list="[scope.row.carFrontPicture]"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column
          prop="carBehindPicture"
          label="车辆后面照片"
          width="170"
        >
          <template slot-scope="scope">
            <el-image
              style="width: 150px; height: 150px"
              :src="scope.row.carBehindPicture"
              :preview-src-list="[scope.row.carBehindPicture]"
            >
            </el-image>
          </template>
        </el-table-column>

        <el-table-column
          fixed="right"
          label="操作"
          width="100"
          align="center"
          v-if="this.state == '' || this.state == '0' || this.state == '1'"
        >
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="goExamine(scope.row)"
              >审 核</el-button
            >
          </template>
        </el-table-column>

        <el-table-column
          fixed="right"
          label="操作"
          width="100"
          v-else-if="this.state == '2'"
        >
          <template slot-scope="scope">
            <el-button @click="cancellation(scope.row)" type="text" size="small"
              >注 销</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <Pagination
        v-show="pageLength >= 0"
        :total="pageLength"
        :limit.sync="pageSize"
        @pagination="handlePageChange"
        :page-sizes="pageSizes"
      />
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/BreadCrumb";
import Pagination from "@/components/Pagination";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import { showLoading, hideLoading } from "@/util/loading";

export default {
  components: {
    Breadcrumb,
    Pagination,
  },
  inject: ["reload"],
  data() {
    return {
      phone: "", // 输入的手机号
      time: "", // 输入的日期
      state: "", // 输入的状态
      tableData: [],

      BreadcrumbCon: [
        {
          name: "日常管理",
        },
        {
          name: "车主审核",
        },
        {
          name: "审核手动/自动/失效列表",
        },
      ], // 面包屑数据

      // 分页数据
      currentPage: 1, // 当前页
      pageLength: 0,
      pageSize: 10, //要传过去的数据 每页多少条数据
      pageSizes: [10, 20, 50, 100],
    };
  },

  mounted() {
    showLoading();
    const opt = {
      url: reqUrl.getDriverCheckList,
      method: "POST",
      params: JSON.stringify({
        endtime: "",
        isAutoCheck: false,
        nonce: "",
        pageNum: 1,
        pageSize: 10,
        phone: "",
        sign: "",
        starttime: "",
        token: "",
        type: 0,
      }),
      resFunc: (res) => {
        if (res.data.code == 0) {
          this.tableData = res.data.data.list;
          this.pageLength = res.data.data.total;
          this.pageSize = res.data.data.pageSize;
          hideLoading();
        }

        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        }
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
        hideLoading();
      },
    };
    request(opt);
  },

  methods: {
    // 分页
    handlePageChange(data) {
      showLoading();
      const opt = {
        url: reqUrl.getDriverCheckList,
        method: "POST",
        params: JSON.stringify({
          endtime: this.time[1],
          isAutoCheck: false,
          nonce: "",
          pageNum: data.pageIndex,
          pageSize: data.pageSize,
          phone: "",
          sign: "",
          starttime: this.time[0],
          token: "",
          type: 0,
        }),
        resFunc: (res) => {
          if (res.data.code == 0) {
            this.tableData = res.data.data.list;
            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
            this.currentPage = data.pageIndex;
            hideLoading();
          }

          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          }
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
          hideLoading();
        },
      };
      request(opt);
    },

    // 搜索函数
    searchList() {
      if (this.phone || this.state || this.time) {
        showLoading();
        const opt = {
          url: reqUrl.getDriverCheckList,
          method: "POST",
          params: JSON.stringify({
            endtime: this.time[1],
            isAutoCheck: false,
            nonce: "",
            pageNum: 0,
            pageSize: 0,
            phone: this.phone,
            sign: "",
            starttime: this.time[0],
            token: "",
            type: this.state,
          }),
          resFunc: (res) => {
            if (res.data.code == 0) {
              this.tableData = res.data.data.list;
              this.pageLength = res.data.data.total;
              this.pageSize = res.data.data.pageSize;
              hideLoading();
            }

            if (res.data.code != 0) {
              this.$notify.error({
                title: "错误",
                message: res.data.message,
              });
              hideLoading();
            }
          },
          errFunc: (err) => {
            this.$notify.error({
              title: "错误",
              message: err,
            });
            console.log(err, "err1");
            hideLoading();
          },
        };
        request(opt);
      } else {
        this.$notify.warning({
          title: "警告",
          message: "暂无可搜索项",
        });
      }
    },

    // 注销
    cancellation(row) {
      this.$confirm("您确定要注销吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const opt = {
            url: reqUrl.cancelDetailDriver,
            method: "POST",
            params: JSON.stringify({
              nonce: "",
              phone: row.phone,
              sign: "",
              token: "",
            }),
            resFunc: (res) => {
              if (res.data.code == 0) {
                this.$message({
                  type: "success",
                  message: "注销成功!",
                });
              }
              if (res.data.code != 0) {
                this.$notify.error({
                  title: "错误",
                  message: res.data.message,
                });
              }
            },
            errFunc: (err) => {
              this.$notify.error({
                title: "错误",
                message: err,
              });
              console.log(err, "err1");
            },
          };
          request(opt);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    // 去审核详情页
    goExamine(row) {
      localStorage.setItem("ownerListStatusUserId", row.userid);
      this.$router.push("/AuditStatusListDetails");
    },

    // 重置按钮
    reset() {
      if (this.phone || this.time || this.state) {
        this.phone = "";
        this.time = "";
        this.state = "";
        this.refresh();
      } else {
        this.$notify.warning({
          title: "警告",
          message: "暂无可重置项",
        });
      }
    },

    refresh() {
      this.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.status {
  display: flex;
  align-items: center;

  .el-input,
  .el-select {
    width: 300px;
  }

  .el-date-editor,
  .el-button,
  .el-select {
    margin-left: 20px;
  }
}
</style>
